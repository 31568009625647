pre.diff-highlight > code .token.deleted:not(.prefix),
pre > code.diff-highlight .token.deleted:not(.prefix) {
	background-color: rgba(255, 0, 0, .1);
	color: inherit;
	display: block;
}

pre.diff-highlight > code .token.inserted:not(.prefix),
pre > code.diff-highlight .token.inserted:not(.prefix) {
	background-color: rgba(0, 255, 128, .1);
	color: inherit;
	display: block;
}
