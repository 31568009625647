/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/prismjs@1.19.0/themes/prism-okaidia.min.css
 * - /npm/prismjs@1.19.0/plugins/line-numbers/prism-line-numbers.min.css
 * - /npm/prismjs@1.19.0/plugins/diff-highlight/prism-diff-highlight.min.css
 * - /npm/prismjs@1.19.0/plugins/toolbar/prism-toolbar.min.css
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
